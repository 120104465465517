<script setup>
import { computed } from 'vue';

// const { blog } = defineProps(['blog'])

const props = defineProps({
  blog: Object
  // favored: Boolean,
});

const formattedDate = computed(() => {
  if (!props.blog || !props.blog.created_at) return '';
  const date = new Date(props.blog.created_at);
  const year = date.getFullYear();
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getMonth()];
  const day = String(date.getDate()).padStart(2, '0');
  return `${year} ${month} ${day}`;
});
</script>

<template>
  <span v-if="blog">
      <div class="pb-2 overflow-hidden bg-slate-50 hover:-translate-y-2 transition ease-in-out delay-150 duration-300 drop-shadow-md rounded-lg flex flex-col h-full">
        <img class="w-full h-full object-cover" :src=blog.image alt="" />
        <div class="flex-1 flex-col p-4 justify-start">
          <div class="flex justify-between items-baseline text-xs text-slate-400 pb-1 border-b mb-2">
            <div id="date-publish" class="">{{ formattedDate }}</div>
            <div id="category" class="">Tips</div>
          </div>
          <h2 class="mt-1 text-lg text-slate-500 font-semibold line-clamp-2 leading-tight">
            {{ blog.judul }}
          </h2>
          <p class="line-clamp-2 mt-2 text-md mb-4 text-slate-500">
            {{ blog.short_desc }}
          </p>
          <!-- <button class="mx-auto px-20 py-2 outline outline-slate-300 rounded-md text-xs w-full">
            Lihat Blog
          </button> -->
        </div>
      </div>
  </span>

</template>
